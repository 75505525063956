import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Layout } from "../components";
import { MetaData } from "../components/common/meta";
import { PrimarySection, SecondarySection } from "../components/common";

const secondaryClassName = (count) => {
  if (count === 3) {
    return "grid md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-20";
  } else if (count === 2) {
    return "grid md:grid-cols-2 gap-x-12 gap-y-20";
  } else {
    return "";
  }
};

// markup
const IndexPage = ({ data, location }) => {
  const heatingAndCoolingPosts = data.heatingAndCoolingPosts.edges;
  const renewableEnergyPosts = data.renewableEnergyPosts.edges;
  const electrificationPosts = data.electrificationPosts.edges;
  const financingPosts = data.financingPosts.edges;
  const faqPosts = data.faqPosts.edges;
  const insulationPosts = data.insulationPosts.edges;
  const latestPosts = data.latestPosts.edges;
  return (
    <>
      <MetaData location={location} />
      <Layout showBanner={true} isHome={true}>
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-16">
          <PrimarySection
            title="Latest"
            posts={latestPosts}
            nextUrl="/pages/2"
            isHome={true}
          />
          <PrimarySection
            title="Heating and Cooling"
            posts={heatingAndCoolingPosts}
            nextUrl="/topics/heating-and-cooling"
            isHome={true}
          />

          <PrimarySection
            title="Electrification"
            posts={electrificationPosts}
            nextUrl="/topics/electrification"
            isHome={true}
          />

          <PrimarySection
            title="Renewable Energy"
            posts={renewableEnergyPosts}
            nextUrl="/topics/renewable-energy"
            isHome={true}
          />

          <PrimarySection
            title="Financing"
            posts={financingPosts}
            nextUrl="/topics/financing"
            isHome={true}
          />

          {/* Only handles up to 3 secondary topics */}
          <div className={secondaryClassName(2)}>
            <SecondarySection
              title="Wildgrid FAQ"
              posts={faqPosts}
              nextUrl="/topics/faq"
            />
            <SecondarySection
              title="Insulation"
              posts={insulationPosts}
              nextUrl="/topics/insulation"
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    latestPosts: PropTypes.object.isRequired,
    insulationPosts: PropTypes.object.isRequired,
    financingPosts: PropTypes.object.isRequired,
    faqPosts: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query HomePageQuery {
    heatingAndCoolingPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "heating-and-cooling" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 7
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    renewableEnergyPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "renewable-energy" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 7
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    electrificationPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "electrification" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 7
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    financingPosts: allGhostPost(
      filter: { primary_tag: { slug: { eq: "financing" } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 7
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    faqPosts: allGhostPost(
      filter: { tags: { elemMatch: { slug: { eq: "faq" } } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 5
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    insulationPosts: allGhostPost(
      filter: { tags: { elemMatch: { slug: { eq: "insulation" } } } }
      sort: { order: DESC, fields: [published_at] }
      limit: 5
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    latestPosts: allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: 5
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;

export default IndexPage;
